import './App.css';
import Home from './page/home';

function App() {
  return (
    <div className="App">
     <Home/>
    </div>
  );
}

export default App;
